<template>
  <div v-if="typeof valueArray=='object'">
    <div v-for="(value, index) in valueArray" :key="index">
      <div v-if="Array.isArray(value)">
        <div v-for="singleValue in value" :key="singleValue">
          {{index}}:{{singleValue}}
          <br />
        </div>
      </div>
      <div v-else>
        {{index}}:{{value}}
        <br />
      </div>
    </div>
  </div>
  <div v-else>{{language.componentLabel.labelEmpty}}</div>
</template>
<script>
export default {
  props: ["row", "header"],
  computed: {
    valueArray() {
      return JSON.parse(this.row[this.header]);
    },
  },
};
</script>